import React, { createContext, Component } from "react";

const LimitContext = createContext();

export class LimitProvider extends Component {
  state = {
    limitAmount: null,
  };

  setLimitAmount = (amount) => {
    this.setState({ limitAmount: amount });
  };

  render() {
    return (
      <LimitContext.Provider
        value={{
          limitAmount: this.state.limitAmount,
          setLimitAmount: this.setLimitAmount,
        }}
      >
        {this.props.children}
      </LimitContext.Provider>
    );
  }
}

export default LimitContext;
