import React, { useContext } from "react";

import LimitContext from "../../context/LimitContext";


export default function SaldoPontos() {
  const context = useContext(LimitContext);
    
  const formattedLimit =
    context.limitAmount !== null && context.limitAmount !== undefined
      ? context.limitAmount.toLocaleString("pt-BR")
      : "0";
  
  return (
    <LimitContext.Consumer>
      {(context) => (
        <div className='col-md-6 mb-4' id="fetch-points-box">
          <div className="card mb-4 download-and-upload">
            <div className="card-header text-center">
                Saldo de Pontos
            </div>
            <div className="card-body" id="fetch-points-body">
              {context.limitAmount === null ? (
                <span>Selecione um parceiro</span>
                ) : (
                  <h2 className="points-number">{formattedLimit} pontos</h2>
                )
              }
            </div>
          </div>
        </div>
      )}
    </LimitContext.Consumer>
  );
}