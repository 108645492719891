import React, { Component } from 'react';
import { parseISO } from 'date-fns';
import axios from 'axios';
import { stateToHTML } from 'draft-js-export-html';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import setEditorValidated from '../../textEditor/RichEditorActions';
import { comparatorDates } from '../../../helpers/GlobalFunctions'
import {
  sessionExpired, 
  generalError,
  showLpCampaign
} from '../../../helpers/GlobalMessages'

import Editor from '../../textEditor/RichEditor';
import iconInfo from '../../../assets/img/icon-info.svg';
import ContainerCategories from '../categories/ContainerCategories';
import Helpers from '../categories/utils/Helpers';

const initialState = {
  partner_name: '',
  partner_code: '',
  campaign_name: '',
  campaign_code: '',
  subPartner_name: '',
  subPartner_code: '',
  subPartnerMonthsPoints: '24',
  disabled_checkbox: true,
  campaign_subpartner: false,
  campaign_subpartner_points: false,
  esferaParity: '0',
  partnerParity: '0',
  sumPoints: '0',
  adjustPartnerPoints: false,
  display: true,
  generalParity: '0 ponto a cada 0 real para clientes em geral',
  clubParity: '0 ponto a cada 0 real para clientes clube',
  flagClubParity: false,
  typePoints: 'SELECT',
  start_date: '',
  deadline: '',
  deadlineCreditPoints: '45',
  creditPhrasePoints: 'Prazo para crédito de pontos é de 45 dias após a entrega do produto.',
  urlImageOCC: '',
  urlPagePartner: '',
  uploadFileCampaignExpired: true,

  partners: [],
  disabled_button: false,
  disabled_select: true,
  loading_message: 'Carregando parceiros...',
  subpartner_loading_message: 'Carregando subparceiros...',
  parityPartnerText: 'Paridade Parceiro',

  partner_name_error: '',
  partner_code_error: '',
  campaign_name_error: '',
  campaign_code_error: '',
  esferaParity_error: '',
  partnerParity_error: '',
  parity_general_code_error: '',
  parity_club_code_error: '',
  club_client_percent_error: '',
  clubClientParity_error: '',
  generalClientPercent_error: '',
  new_client_parity_error: '',
  typePoints_error: '',
  start_date_error: '',
  deadline_error: '',
  credits_points_error: '',
  deadlineCreditPoints_error: '',
  creditPhrasePoints_error: '',
  urlPagePartner_error: '',
  subPartner_name_error: '',
  subPartner_code_error: '',
  subPartnerMonthsPoints_error: '',
  selectCategories_error: false,

  clubClientParity: '0',
  campaignRuleUrl: '',
  clubClientPercent: '0',
  newClientParity: '0',
  generalClientPercent: '0',
  accumulationLimit: null,
  text_editor_content: '',
  text_editor_content_error: '',
};

const BASE_URL = `${window.REACT_APP_URL}`;
const config = {
  headers: {
    'content-type': 'application/json',
  },
};

class FormCampaign extends Component {
  state = initialState;

  componentDidMount() {
    const BASE_URL = `${window.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner/simple`)
      .then((response) => {
        const partnersData = response.data.results;

        partnersData.sort(function (a, b) {
          if (a.partnerName < b.partnerName) {
            return -1;
          }
          if (a.partnerName > b.partnerName) {
            return 1;
          }
          return 0;
        });

        if (response.data.results) {
          this.setState({
            partners: partnersData,
            disabled_select: false,
          });
        } else {
          this.setState({
            partners: [],
            disabled_select: false,
          });
        }
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === '401') {
          this.props.logout();
        }
      });

    this.props.setEditorValidated(false);
  }

  validade = () => {
    let partner_name_error = '';
    let partner_code_error = '';
    let campaign_name_error = '';
    let campaign_code_error = '';
    let typePoints_error = '';
    let esferaParity_error = '';
    let partnerParity_error = '';
    let parity_general_code_error = '';
    let parity_club_code_error = '';
    let club_client_percent_error = '';
    let clubClientParity_error = '';
    let new_client_parity_error = '';
    let generalClientPercent_error = '';
    let start_date_error = '';
    let deadline_error = '';
    let deadlineCreditPoints_error = '';
    let creditPhrasePoints_error = '';
    let urlPagePartner_error = '';
    let text_editor_content_error = '';
    let subPartner_name_error = '';
    let subPartner_code_error = '';
    let subPartnerMonthsPoints_error = '';
    let selectCategories_error = false;

    if (!this.state.partner_name) {
      partner_name_error = 'Nome do parceiro inválido';
    }

    if (!this.state.partner_code || this.state.partnerCode === 'select') {
      partner_code_error = 'Código do parceiro inválido';
    }

    if (!this.state.campaign_name) {
      campaign_name_error = 'Nome da campanha inválido';
    }

    if (!this.state.campaign_code) {
      campaign_code_error = 'Código da campanha inválido';
    }

    if (!this.state.typePoints || this.state.typePoints === 'SELECT') {
      typePoints_error = 'Tipo de ponto inválido';
    }

    if (!this.state.esferaParity) {
      esferaParity_error = 'Código de paridade esfera inválido';
    }

    if (!this.state.partnerParity) {
      partnerParity_error = 'Código de paridade parceiro inválido';
    }

    if (!this.state.generalParity) {
      parity_general_code_error = 'Código de paridade geral inválido';
    }

    if(this.state.flagClubParity) {
      if (!this.state.clubParity) {
        parity_club_code_error = 'Código de paridade de clube inválido';
      }
    }

    if (!this.state.clubClientPercent || this.state.clubClientPercent > 100) {
        club_client_percent_error = 'É permitido somente número de 0 a 100';
    }

    if (this.state.partner_code === 'SHP' && (!this.state.newClientParity || this.state.newClientParity > 100)) {
        new_client_parity_error = 'É permitido somente número de 0 a 100';
    }

    if (this.state.generalClientPercent.length === 0 || this.state.generalClientPercent > 100) {
        generalClientPercent_error = 'É permitido somente número de 0 a 100';
    }

    if (this.state.clubClientParity.length === 0) {
        clubClientParity_error = 'É permitido somente número de 0 a 99';
    }

    if (!this.state.start_date) {
      start_date_error = 'Data de início inválida';
    }

    if (!this.state.deadline) {
      deadline_error = 'Data final inválida';
    }

    if (this.state.deadlineCreditPoints === '' || this.state.deadlineCreditPoints === ' ') {
      deadlineCreditPoints_error = 'Prazo para crédito dos pontos inválido';
    }

    if (!this.state.creditPhrasePoints) {
      creditPhrasePoints_error = 'Frase para créditos dos pontos inválida';
    }

    if(!this.state.urlPagePartner) {
      urlPagePartner_error = 'URL da pagina do parceiro inválida';
    }

    if (this.props.editorValidated === false) {
      text_editor_content_error = 'Regulamento inválido';
    }

    if (this.state.deadline !== '') {
      const result = comparatorDates(this.state.start_date, this.state.deadline);
      if (result) {
        deadline_error = 'Data final deve ser após a data inicial';
      }
    }

    if (!this.state.subPartner_name && this.state.campaign_subpartner) {
      subPartner_name_error = 'Nome do Subparceiro inválido';
    }

    if (!this.state.subPartner_code && this.state.campaign_subpartner) {
      subPartner_code_error = 'Código do Subparceiro inválido';
    }

    if (this.state.subPartnerMonthsPoints === '' || this.state.subPartnerMonthsPoints === ' ') {
      subPartnerMonthsPoints_error = 'Número de meses inválido';
    }

    selectCategories_error = 
      this.state.partner_accept_category && !Helpers.isValidCategories(this.props.categories)
        ? true : false

    if (
      partner_name_error ||
      partner_code_error ||
      campaign_name_error ||
      campaign_code_error ||
      subPartner_name_error ||
      subPartner_code_error ||
      subPartnerMonthsPoints_error ||
      typePoints_error ||
      esferaParity_error ||
      partnerParity_error ||
      parity_general_code_error ||
      parity_club_code_error ||
      club_client_percent_error ||
      clubClientParity_error ||
      generalClientPercent_error ||
      new_client_parity_error ||
      start_date_error ||
      deadline_error ||
      deadlineCreditPoints_error ||
      creditPhrasePoints_error ||
      urlPagePartner_error ||
      text_editor_content_error ||
      !Helpers.isValidCategories(this.props.categories)
    ) {
      this.setState({
        partner_name_error,
        partner_code_error,
        subPartner_name_error,
        subPartner_code_error,
        subPartnerMonthsPoints_error,
        campaign_name_error,
        campaign_code_error,
        typePoints_error,
        esferaParity_error,
        partnerParity_error,
        parity_general_code_error,
        parity_club_code_error,
        club_client_percent_error,
        generalClientPercent_error,
        clubClientParity_error,
        generalClientPercent_error,
        start_date_error,
        deadline_error,
        deadlineCreditPoints_error,
        creditPhrasePoints_error,
        urlPagePartner_error,
        text_editor_content_error,
        selectCategories_error
      });
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validade();
    const subPartner = this.state.subPartners;
    const checkedMonthsToPointsExpire = this.state.campaign_subpartner_points;
    let subPartners = null;
    let monthsToPointsExpire = null
    
    if(checkedMonthsToPointsExpire){
      monthsToPointsExpire = this.state.subPartnerMonthsPoints;
    }

    if (subPartner) {
      subPartners = {
          subPartnerCode: this.state.subPartner_code,
      };
    }

    if (isValid) {
      if (this.state.disabled_button) {
        return;
      }

      this.setState({
        disabled_button: true,
      });

      let campaign_rule = '';
      if (this.state.text_editor_content != '') {
        campaign_rule = {
          campaignCode: this.state.campaign_code,
          campaignName: this.state.campaign_name,
          campaignRule: this.state.text_editor_content,
          campaignRuleUrl: this.state.campaignRuleUrl
        };
      }

      var campaign;
      if(!this.state.flagClubParity) {
        campaign = {
          partnerName: this.state.partner_name,
          subPartner: subPartners,
          monthsToPointsExpire: monthsToPointsExpire,
          partnerCode: this.state.partner_code,
          campaignName: this.state.campaign_name,
          campaignCode: this.state.campaign_code,
          esferaParity: this.state.esferaParity,
          partnerParity: this.state.partnerParity,
          adjustPartnerPoints: this.state.adjustPartnerPoints,
          display: this.state.display,
          generalParity: this.state.generalParity,
          flagClubParity: this.state.flagClubParity,
          clubClientPercent: this.state.clubClientPercent,
          generalClientPercent: this.state.generalClientPercent,
          clubClientParity: this.state.clubClientParity,
          accumulationLimit: this.state.accumulationLimit !== '' ? this.state.accumulationLimit : null,
          typePoints: this.state.typePoints,
          startDate: this.state.start_date,
          deadline: this.state.deadline,
          deadlineCreditPoints: this.state.deadlineCreditPoints,
          creditPhrasePoints: this.state.creditPhrasePoints,
          urlImageOCC: this.state.urlImageOCC,
          urlPagePartner: this.state.urlPagePartner,
          uploadFileCampaignExpired: this.state.uploadFileCampaignExpired,
        };
      } else {
        campaign = {
          partnerName: this.state.partner_name,
          partnerCode: this.state.partner_code,
          subPartner: subPartners,
          monthsToPointsExpire: monthsToPointsExpire,
          campaignName: this.state.campaign_name,
          campaignCode: this.state.campaign_code,
          esferaParity: this.state.esferaParity,
          partnerParity: this.state.partnerParity,
          adjustPartnerPoints: this.state.adjustPartnerPoints,
          display: this.state.display,
          generalParity: this.state.generalParity,
          clubParity: this.state.clubParity,
          clubClientPercent: this.state.clubClientPercent,
          generalClientPercent: this.state.generalClientPercent,
          clubClientParity: this.state.clubClientParity,
          accumulationLimit: this.state.accumulationLimit,
          flagClubParity: this.state.flagClubParity,
          typePoints: this.state.typePoints,
          startDate: this.state.start_date,
          deadline: this.state.deadline,
          deadlineCreditPoints: this.state.deadlineCreditPoints,
          creditPhrasePoints: this.state.creditPhrasePoints,
          urlImageOCC: this.state.urlImageOCC,
          urlPagePartner: this.state.urlPagePartner,
          uploadFileCampaignExpired: this.state.uploadFileCampaignExpired,
        };
      }

      if(this.state.partner_code && this.state.partner_code === 'SHP') campaign['newClientParity'] = this.state.newClientParity

      if(this.state.partner_accept_category) {
        campaign.categories = Helpers.sanitizeCategories(this.props.categories)
      }

      axios
      .post(`${BASE_URL}/campaign`, campaign, config)
      .then((response) => {
        this.setState(initialState);
        this.createCampaignRule(campaign_rule);
        if(document.getElementById('form-container')) document.getElementById('form-container').reset();
        this.props.handleSendFormCampaignSuccess(
        'Campanha cadastrada com sucesso!'
        );
        })
        .catch((error) => {
          this.setState(initialState);
          if(document.getElementById('form-container')) document.getElementById('form-container').reset();

          if (error.response) {
            const httpStatusError = JSON.stringify(error.response.status);
            if (httpStatusError === '401') {
              this.props.handleSendFormCampaignFail(sessionExpired());
              return;
            } else {
              const message = error.response?.data?.results?.userMessage;
              if (message) {
                this.props.handleSendFormCampaignFail(message);
                return;
              }
            }
          }
          this.props.handleSendFormCampaignFail(generalError());
        });
    }
  };

  createCampaignRule = (campaign_rule) => {
    axios.post(`${BASE_URL}/rule`, campaign_rule, config).catch((error) => {
      this.setState(initialState);
      if (document.getElementById('form-container')) document.getElementById('form-container').reset();
      if (error.response) {
        const httpStatusError = JSON.stringify(error.response.status);
        if (httpStatusError === '401') {
          this.props.handleSendFormCampaignFail(sessionExpired());
          return;
        } else {
          const message = error.response?.data?.results?.userMessage;
          if (message) {
            this.props.handleSendFormCampaignFail(message);
            return;
          }
        }
      }
      this.props.handleSendFormCampaignFail(generalError());
    });
  }

  handleTextInputChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + '_error']: '',
      successMsg: '',
    });
  };

  handleSelectTypePoints = (event) => {
    event.preventDefault();
    this.setState({
      typePoints: event.target.value,
      typePoints_error: '',
      successMsg: '',
    });
  }
  
  hundleTextCampaignCode = (event) => {
    event.preventDefault();
    var er = /[^a-zA-Z0-9]/;
    er.lastIndex = 0;
    
    if(!er.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
          [event.target.name + '_error']: '',
          successMsg: '',
        });
    } else {
        event.target.value = '';
    }
  };

  handleNumberInputChange = (event) => {
    event.preventDefault();
    var er = /[^0-9.]/;
    er.lastIndex = 0;
    
    if(!er.test(event.target.value)) {
        var sumPoints = this.state.sumPoints;
        this.setState({
          [event.target.name]: event.target.value,
          [event.target.name + '_error']: '',
          successMsg: ''
        });
        this.setState((state, _) => {
          return {
            sumPoints: Helpers.sumParities(state.esferaParity, state.partnerParity)
          }
        })
    } else {
        event.target.value = '';
    }
  };

  handleNumberInputChangeCustom = (event) => {
    event.preventDefault();

    const valueMask = Helpers.maskParity(event.target.value)
    this.setState({
      [event.target.name]: valueMask,
      [event.target.name + '_error']: '',
      successMsg: ''
    });
    this.setState((state, _) => ({
        sumPoints:  Helpers.sumParities(state.esferaParity, state.partnerParity)
    }))
  };

  handleDateInputChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: parseISO(event.target.value),
      [event.target.name + '_error']: '',
      successMsg: '',
    });
  };

  cleanStartDateInput = (event) => {
    event.preventDefault();
    this.setState({
      start_date: '',
    });
    document.getElementById('start_date').value = '';
  };

  cleanDeadlineInput = (event) => {
    event.preventDefault();
    this.setState({
      deadline: '',
    });
    document.getElementById('deadline').value = '';
  };

  handleChangeSelectMenu = (event) => {
    if (event.target.value === 'select') {
      this.setState({
        partner_code: '',
        partner_name: '',
        parityPartnerText: 'Paridade Parceiro',
      });
    } else {
      const partner_code_selected = event.target.value;
      const partner_found = this.state.partners.filter((partner) => {
        return partner.partnerCode === partner_code_selected;
      });
      const partnerText = partner_found[0].partnerCode === 'SHP' ? 'Paridade Recorrente' : 'Paridade Parceiro';
      this.setState({
        partner_code: partner_code_selected,
        partner_name: partner_found[0].partnerName,
        partner_id: partner_found[0].id,
        partner_accept_category: partner_found[0].acceptCategory,
        partner_code_error: '',
        partner_name_error: '',
        parityPartnerText: partnerText,
      }, () => {
        this.getSubpartnersMenu();
      });
    }
  };

  handleRadioInputChange = (event) => {
    if (event.target.value === 'radio-type-code') {
      this.setState({
        campaign_code_automatic: false,
        campaign_code: '',
        campaign_code_error: '',
      });
      document.getElementById('campaign_code').value = '';
    } else {
      this.setState({
        campaign_code_automatic: true,
        campaign_code: this.generateRandomCampaignCode(),
        campaign_code_error: '',
      });
    }
  };

  generateRandomCampaignCode = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result = result.concat(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result;
  };

  handleTextEditorChange = (text_editor_content) => {
    this.setState({
      text_editor_content: stateToHTML(text_editor_content.getCurrentContent()),
    });
  };

  handleChangeCheck = (event) => {
    if (event.target.name === 'campaign_subpartner') {
      this.setState({
        subPartner_code: '',
        subPartner_name: '',
      });
    }
    
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  handleChangeSelectMenuSubpartner = (event) => {
    if (event.target.value === 'select') {
      this.setState({
        subPartner_code: '',
        subPartner_name: '',
      });
    } else {
      const subPartner_code_selected = event.target.value;
      const subpartner_found = this.state.subPartners.filter((subPartner) => {
        return subPartner.subPartnerCode === subPartner_code_selected;
      });
      this.setState({
        subPartner_code: subPartner_code_selected,
        subPartner_name: subpartner_found[0].subPartnerName,
        subPartner_code_error: '',
        subPartner_name_error: '',
      });
    }
  };

  handleKeyPressStartDate = (event) => {
    if(event.key === "Backspace") {
      this.setState({
        start_date: '',
      });
      document.getElementById('start_date').value = '';
    }
  };

  handleKeyPressDeadline = (event) => {
    if(event.key === "Backspace") {
      this.setState({
        deadline: '',
      });
      document.getElementById('deadline').value = '';
    }
  };

  handleNumberPermition = (event) => {
    event.preventDefault();
    const regexNumberPermition = /^[1-9][0-9]{0,2}$/;

    if (event.target.value === '' || regexNumberPermition.test(event.target.value)) {
        const numericValue = parseInt(event.target.value, 10);
        if (event.target.value === '' || (numericValue >= 1 && numericValue <= 999)) {
            this.setState({
                [event.target.name]: event.target.value,
                [event.target.name + '_error']: '',
                successMsg: ''
            });
        }
    }
  };

  getSubpartnersMenu = () => {
    const BASE_URL = `${window.REACT_APP_URL}`;
    const partinerId = this.state.partner_id;

    if(!partinerId) return

    axios
      .get(`${BASE_URL}/partner/locations?partnerId=${partinerId}`)
      .then((response) => {
        const subpartnersData = response.data.results.subPartners;

      
        if (subpartnersData) {      
          this.setState({
            subPartners: subpartnersData,
            disabled_select: false,
            disabled_checkbox: false,
          });

        } else {
          this.setState({
            disabled_select: false,
            disabled_checkbox: true,
          });
        }
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === '401') {
          this.props.logout();
        }
      });

    this.props.setEditorValidated(false);
  }

  render() {
    return (
      <div id='page-wrapper'>
        <form
          id='form-container'
          className='container'
          onSubmit={this.handleSubmit.bind(this)}
        >
          <div className='input-block' id='partner_name_container'>
            <label htmlFor='partner_name'>Nome do Parceiro</label>

            {this.state.disabled_select === false ? (
              <select
                onChange={this.handleChangeSelectMenu}
                value={this.state.partner_code}
              >
                <option value='select'>Selecione</option>
                {this.state.partners.map((partner) => (
                  <option key={partner.partnerCode} value={partner.partnerCode}>
                    {partner.partnerName}
                  </option>
                ))}
              </select>
            ) : (
              <div className='loading-msg'>{this.state.loading_message}</div>
            )}
          </div>
          <div className='error-msg'>{this.state.partner_name_error}</div>

          <div className='input-block'>
            <label htmlFor='partner_name'>Código do Parceiro</label>
            <input
              className='hidden'
              type='text'
              id='partner_code'
              name='partner_code'
              value={this.state.partner_code}
              readOnly={true}
            />
          </div>
          <div className='error-msg'>{this.state.partner_code_error}</div>

          <div>
            <div className='checkbox_block'>
              <input className='campaign_subpartner'
                type='checkbox'
                id='campaign_subpartner'
                name='campaign_subpartner'
                onChange={(event) => {
                    this.handleChangeCheck(event);
                }}
                checked={this.state.campaign_subpartner}
                disabled={this.state.disabled_checkbox}
              />

              <label className='checkBox-space' htmlFor='campaign_subpartner'>Campanha para Subparceiro?</label>
            </div>

            {
              this.state.campaign_subpartner && !this.state.disabled_checkbox ? (
                <div>
                  <div className='input-grid ' id='subPartner_name_container'>
                    <label htmlFor='subPartner_code'>Nome do Subparceiro</label>

                    {this.state.disabled_select === false ? (
                      <select
                        onChange={this.handleChangeSelectMenuSubpartner}
                        value={this.state.subPartnerCode}
                      >
                        <option value='select'>Selecione</option>
                        {this.state.subPartners && this.state.subPartners.map((subpartner) => (
                          <option key={subpartner.subPartnerCode} value={subpartner.subPartnerCode}>
                            {subpartner.subPartnerName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className='loading-msg'>{this.state.subpartner_loading_message}</div>
                    )}
                  </div>
                  <div className='error-msg'>{this.state.subPartner_name_error}</div>

                  <div className='input-block'>
                    <label htmlFor='subPartner_name'>Código do Subparceiro</label>
                    <input
                      className='hidden'
                      type='text'
                      id='subPartner_code'
                      name='subPartner_code'
                      value={this.state.subPartner_code}
                      readOnly={true}
                    />
                  </div>
                  <div className='error-msg'>{this.state.subPartner_code_error}</div>
                </div>
              ) : null 
            }
          </div>

          <div className='input-block'>
            <label htmlFor='campaign_name'>Nome da Campanha</label>
            <span> (até 40 caracteres)</span>
            <input
              type='text'
              id='campaign_name'
              name='campaign_name'
              onChange={this.handleTextInputChange}
              maxLength='40'
            />
          </div>
          <div className='error-msg'>{this.state.campaign_name_error}</div>

          <div className='input-block'>
            <label htmlFor='campaign_code'>Código da Campanha</label>
            <span> (até 10 caracteres)</span>

            <fieldset>
              <div className='radio-div'>
                <input
                  type='radio'
                  value='radio-automactic-code'
                  name='campaign-code'
                  id='radio-automactic-code'
                  onChange={this.handleRadioInputChange}
                />
                <label htmlFor='radio-automactic-code'>
                  Gerar um novo código automaticamente
                </label>
              </div>

              <div className='radio-div'>
                <input
                  type='radio'
                  value='radio-type-code'
                  name='campaign-code'
                  id='radio-type-code'
                  onChange={this.handleRadioInputChange}
                />
                <label htmlFor='radio-type-code'>Inserir um código</label>
              </div>
            </fieldset>

            {this.state.campaign_code_automatic ? (
              <input
                className='hidden'
                type='text'
                id='campaign_code'
                name='campaign_code'
                value={this.state.campaign_code}
                maxLength='10'
                readOnly={true}
              />
            ) : (
              <input
                type='text'
                id='campaign_code'
                name='campaign_code'
                value={this.state.campaign_code}
                onChange={this.hundleTextCampaignCode}
                maxLength='10'
              />
            )}
          </div>
          <div className='error-msg'>{this.state.campaign_code_error}</div>

          <label htmlFor="typePoints">Tipo de ponto</label>
          <select value={this.state.typePoints} onChange={this.handleSelectTypePoints}>
            <option value="SELECT">Selecione</option>
            <option value="BONUS">Bônus</option>
            <option value="BASE">Base</option>
            <option value="AMEX">Amex</option>
            <option value="CLUB">Club</option>
            <option value="INCT">Incentivos</option>
          </select>
          <div className='error-msg'>{this.state.typePoints_error}</div>

          <div>
            <div className='checkbox_block'>
              <input
                className='campaign_subpartner_points'
                type='checkbox'
                id='campaign_subpartner_points'
                name='campaign_subpartner_points'
                onChange={(event) => {
                  this.handleChangeCheck(event);
                }}
                checked={this.state.campaign_subpartner_points}
              />
              <label className='checkBox-space' htmlFor='campaign_subpartner_points'>Definir expiração dos pontos?</label>
            </div>

            { 
              this.state.campaign_subpartner_points ? (
                <div className='input-block'>
                  <label htmlFor='subPartnerMonthsPoints'>Duração dos pontos em Meses</label>
                  <span className='span-color'> (até 3 caracteres)</span>
                  <div class="popover__wrapper">
                    <img src={iconInfo} alt="" />
                    <div class="popover__content" id='popover_subpartner'>
                      <p class="popover__message">Meses a adicionar a data de criação dos pontos para definir data de expiração.</p>
                    </div>
                  </div>
                  <input
                    type='text'
                    id='subPartnerMonthsPoints'
                    name='subPartnerMonthsPoints'
                    onChange={this.handleNumberPermition}
                    value={this.state.subPartnerMonthsPoints}
                    maxLength='3'
                    pattern='\d*'
                  />
                  <div className='error-msg'>{this.state.subPartnerMonthsPoints_error}</div>
                </div>
                
              ) : null
            }
          </div>
          {
            this.state.partner_accept_category &&
            <ContainerCategories 
              partnerId={this.state.partner_id} 
              categoryHasError={this.state.selectCategories_error}
            />
          }
          
          {
            this.state.partner_accept_category &&
            (
              <div className='input-block'>
                <span class="popover__details">Paridades para as demais categorias: </span>
                <div class="popover__wrapper">
                    <img src={iconInfo} alt="" />
                    <div class="popover__content">
                        <p class="popover__message">A paridade configurada neste campo, será aplicada a todas as categorias de produtos do parceiro, que não foram selecionadas anteriormente. Preencher esse campo para paridade BAU.</p>
                    </div>
                </div>
              </div>
            )
          }

          <div className="div-parity">
            <div className='input-block input-block-inside-div'>
              <label htmlFor='esferaParity'>Paridade Esfera {this.state.partner_accept_category && (<> – Clientes Geral</>)}</label>
              <span> (até 4 caracteres) </span>
              <div class="popover__wrapper">
                <img src={iconInfo} alt="" />
                <div class="popover__content">
                  <p class="popover__message">Pontos por Real que o cliente vai receber, pagos pela Esfera.</p>
                </div>
              </div>
              <input
                type='text'
                id='esferaParity'
                name='esferaParity'
                onChange={this.handleNumberInputChangeCustom}
                value={this.state.esferaParity}
                maxLength='5'
              />
            </div>
            <div className='error-msg'></div>

            <div className="div-plus">
              <span className="plus">+</span>
            </div>

            <div className='input-block'>
              <label htmlFor='partnerParity'>{this.state.parityPartnerText}{this.state.partner_accept_category && (<> – Clientes Geral</>)}</label>
              <span> (até 4 caracteres) </span>
              <div class="popover__wrapper">
                <img src={iconInfo} alt="" />
                <div class="popover__content">
                  <p class="popover__message">Pontos por Real que o cliente vai receber, pagos pelo parceiro.</p>
                </div>
              </div>
              <input
                type='text'
                id='partnerParity'
                name='partnerParity'
                onChange={this.handleNumberInputChangeCustom}
                value={this.state.partnerParity}
                maxLength='5'
              />
            </div>
            <div className='error-msg'></div>
          </div>

          {(this.state.esferaParity_error || this.state.partnerParity_error) ? (
            <div className="div-parity-error">
              <div className='input-block input-block-inside-div-error'>
                <div className='error-msg-parity'>{this.state.esferaParity_error}</div>
              </div>

              <div className="div-plus">
                <span className="plus"></span>
              </div>

              <div className='input-block'>
                <div className='error-msg-parity'>{this.state.partnerParity_error}</div>
              </div>
            </div>        
            ) : null
          }

          <span className="sum-points">= &nbsp; <strong>{this.state.sumPoints} pts</strong> &nbsp; a cada R$ 1 Real em compras</span>

          <div className='switch__container-points'>              
            <input
              name="adjustPartnerPoints"
              type='checkbox'
              className='switch switch--shadow'
              id='adjustPartnerPoints'
              onChange={this.handleChangeCheck.bind()} 
              checked={this.state.adjustPartnerPoints}
            />
            <label htmlFor='adjustPartnerPoints'></label>
            <div className="div-switch">
              <span className="span-title-switch">Ajuste de Pontos</span>
              <span className="span-p-switch">O parceiro enviará a relação de compras com paridade de 1 ponto a cada real. O sistema fará o ajuste automático da paridade.</span>
            </div>
          </div>
          
          <div className='switch__container-campaign'>
            <input id='display' className='switch switch--shadow' type="checkbox" name="display" onChange={this.handleChangeCheck.bind()} checked={this.state.display} />
            <label htmlFor="display"></label>
            <div className="div-switch">
              <span className="span-title-switch">{showLpCampaign()}</span>
              <span className="span-p-switch">Esta campanha ficará visível na página de histórico de campanhas.</span>
            </div>
          </div>

          <div className='input-block'>
              <label htmlFor='generalClientPercent'>Porcentagem a mais para Clientes em Geral</label>
              <span> (até 3 caracteres)</span>
              <input
              type='text'
              id='generalClientPercent'
              name='generalClientPercent'
              onChange={this.handleNumberInputChange}
              value={this.state.generalClientPercent}
              maxLength='3'
              />
          </div>

          <div className='input-block'>
            <label htmlFor='generalParity'>Frase do Extrato de pontos do cliente</label>
            <span> (até 50 caracteres)</span>
            <div class="popover__wrapper">
              <img src={iconInfo} alt="" />
              <div class="popover__content">
                  <p class="popover__message"><b>Campanha sem paridade por categoria</b>: “0 pontos a cada 0 real para clientes em geral”. <b>Campanha com paridade por categoria</b>: “Consultar as paridades da campanha no regulamento".</p>
              </div>
            </div>
            <input
              type='text'
              id='generalParity'
              name='generalParity'
              onChange={this.handleTextInputChange}
              value={this.state.generalParity}
              maxLength='50'
            />
          </div>
          <div className='error-msg'>{this.state.parity_general_code_error}</div>

          <div>
            <input type="checkbox" name="flagClubParity" onChange={this.handleChangeCheck.bind()} checked={this.state.flagClubParity} />
            <label htmlFor="flagClubParity" className='checkBox-space' >
              Exibir a Frase de Paridade Clube na LP de Campanhas?
            </label>
            {this.state.flagClubParity ? (<span className='span-color'> (até 50 caracteres)</span>) : null}
          </div>
          {
            this.state.flagClubParity ? (
              <div>
                <div className='input-block'>
                  <input
                    type='text'
                    id='clubParity'
                    name='clubParity'
                    onChange={this.handleTextInputChange}
                    value={this.state.clubParity}
                    maxLength='50'
                  />
                </div>
                <div className='error-msg'>{this.state.parity_club_code_error}</div>
              </div>
            ) : null
          }

          <div className='input-block'>
            <label htmlFor='clubClientParity'>Paridade a mais para Cliente Clube</label>
            <span> (até 2 caracteres)</span>
            <input
              type='text'
              id='clubClientParity'
              name='clubClientParity'
              onChange={this.handleNumberInputChange}
              value={this.state.clubClientParity}
              maxLength='2'
            />
          </div>
          <div className='error-msg'>{this.state.clubClientParity_error}</div>

          <div className='input-block'>
              <label htmlFor='clubClientPercent'>Porcentagem a mais para Clientes Clube</label>
              <span> (até 3 caracteres)</span>
              <input
              type='text'
              id='clubClientPercent'
              name='clubClientPercent'
              onChange={this.handleNumberInputChange}
              value={this.state.clubClientPercent}
              maxLength='3'
              />
          </div>
          <div className='error-msg'>{this.state.club_client_percent_error}</div>

          {(this.state.partner_code === 'SHP') ? (
            <>
            <div className='input-block'>
              <label htmlFor='newClientParity'>Paridade para usuários novos</label>
              <span> (até 2 caracteres)</span>
              <input
              type='text'
              id='newClientParity'
              name='newClientParity'
              onChange={this.handleNumberInputChange}
              value={this.state.newClientParity}
              
              maxLength='3'
              />
          </div>
          <div className='error-msg'>{this.state.new_client_parity_error}</div>
          </>
          ): null}

          <div className='input-block'>
              <label htmlFor='accumulationLimit'>Limite máximo de acúmulo de pontos nesta campanha</label>
              <input
              type='text'
              id='accumulationLimit'
              name='accumulationLimit'
              onChange={this.handleNumberInputChange}
              value={this.state.accumulationLimit}
              />
          </div>

          <div className='input-block'>
            <label htmlFor='start_date'>Data de Início</label>
            <div className='input-date-area'>
              <input
                type='datetime-local'
                id='start_date'
                name='start_date'
                onKeyDown={this.handleKeyPressStartDate}
                onChange={this.handleDateInputChange}
              />
              <button
                id='clean-start-date-input'
                onClick={this.cleanStartDateInput.bind(this)}
              >
                Limpar
              </button>
            </div>
          </div>
          <div className='error-msg'>{this.state.start_date_error}</div>

          <div className='input-block'>
            <label htmlFor='deadline'>Data de Fim</label>
            <div className='input-date-area'>
              <input
                type='datetime-local'
                id='deadline'
                name='deadline'
                onKeyDown={this.handleKeyPressDeadline}
                onChange={this.handleDateInputChange}
              />
              <button
                id='clean-deadline-input'
                onClick={this.cleanDeadlineInput.bind(this)}
              >
                Limpar
              </button>
            </div>
          </div>
          <div className='error-msg'>{this.state.deadline_error}</div>

          <div className='input-block'>
            <label htmlFor='deadlineCreditPoints'>Prazo para Crédito dos Pontos (dias)</label>
            <span> (até 4 caracteres)</span>
            <input
              type='text'
              id='deadlineCreditPoints'
              name='deadlineCreditPoints'
              onChange={this.handleNumberInputChange}
              value={this.state.deadlineCreditPoints}
              maxLength='4'
            />
          </div>
          <div className='error-msg'>{this.state.deadlineCreditPoints_error}</div>

          <div className='input-block'>
            <label htmlFor='creditPhrasePoints'>Frase dos Créditos dos Pontos</label>
            <input
              type='text'
              id='creditPhrasePoints'
              name='creditPhrasePoints'
              onChange={this.handleTextInputChange}
              value={this.state.creditPhrasePoints}
            />
          </div>
          <div className='error-msg'>{this.state.creditPhrasePoints_error}</div>

          <div className='input-block'>
            <label htmlFor='urlImageOCC'>URL da Imagem da Campanha (URL da imagem cadastrada no OCC)</label>
            <input
              type='text'
              id='urlImageOCC'
              name='urlImageOCC'
              onChange={this.handleTextInputChange}
              value={this.state.urlImageOCC}
            />
          </div>

          <div className='input-block'>
            <label htmlFor='urlPagePartner'>URL da página da oferta do Parceiro</label>
            <input
              type='text'
              id='urlPagePartner'
              name='urlPagePartner'
              onChange={this.handleTextInputChange}
              value={this.state.urlPagePartner}
            />
          </div>
          <div className='error-msg'>{this.state.urlPagePartner_error}</div>

          <div>
            <input type="checkbox" name="uploadFileCampaignExpired" onChange={this.handleChangeCheck.bind()} checked={this.state.uploadFileCampaignExpired} />
            <label htmlFor="uploadFileCampaignExpired" className='checkBox-space' >
              Permitir subir novos arquivos de pontuação para a campanha expirada?
            </label>
          </div>

          <div className='input-block'>
            <label htmlFor='deadline'>Regulamento</label>
            <Editor
              handleTextEditorChange={this.handleTextEditorChange}
              ref='draftRef'
            />
          </div>
          <div className='error-msg'>{this.state.text_editor_content_error}</div>

          <div className='input-block'>
            <label htmlFor='campaignRuleUrl'>Regulamento Completo</label>
            <input
              type='text'
              id='campaignRuleUrl'
              name='campaignRuleUrl'
              onChange={this.handleTextInputChange}
              value={this.state.campaignRuleUrl}
            />
          </div>


          <div className='button-wrapper'>
            <button
              type='submit'
              className='button-admin'
              id='send-form'
              disabled={this.state.disabled_button}
            >
              {this.state.disabled_button ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ 
  editorValidated: state.editor.editorValidated,
  categories: state.select.categories
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEditorValidated }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormCampaign);