import React, { Component } from "react";

import UploadArquivoUser from "../../components/upload/UploadUser";
import UploadArquivoAdmin from "../../components/upload/UploadAdmin";
import DownloadArquivo from "../../components/download/Download";
import SaldoPontos from "../../components/fetchPoints/fetchPoints";
import Table from "../../components/table/Table";

export default class Import extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled_upload: true,
    };

    this.loadUserCompany = this.loadUserCompany.bind(this);
  }

  async loadUserCompany() {
    return this.props.company;
  }

  render() {
    if (this.props.isAdmin === true) {
      return (
        <div>
          <div
            className="row wow fadeIn text-center"
            id="upload-and-download-container"
          >
            <SaldoPontos
              keycloak={this.props.keycloak} 
              logout={this.props.logout} 
            />
            <DownloadArquivo 
              keycloak={this.props.keycloak} 
              logout={this.props.logout} 
            />
            <UploadArquivoAdmin
              isAdmin={this.props.isAdmin}
              keycloak={this.props.keycloak}
              logout={this.props.logout}
            />
          </div>

          <Table 
            keycloak={this.props.keycloak} 
            logout={this.props.logout}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="row wow fadeIn text-center"
            id="upload-and-download-container"
          >
            <SaldoPontos
              keycloak={this.props.keycloak} 
              logout={this.props.logout} 
            />
            <DownloadArquivo 
              keycloak={this.props.keycloak} 
              logout={this.props.logout}
            />
          </div>
          <div className="row wow fadeIn text-center">
            <UploadArquivoUser
              isAdmin={this.props.isAdmin}
              loadUserCompany={this.loadUserCompany}
              company={this.props.company}
              keycloak={this.props.keycloak}
              logout={this.props.logout}
            />
          </div>

          <Table 
            keycloak={this.props.keycloak} 
            logout={this.props.logout}  
          />
        </div>
      );
    }
  }
}
